<template>
  <div v-if="weight || price" class="flex flex-wrap gap-1">
    <slot name="beforePrice" />
    <span v-if="weight">{{ weight }}{{ $t('general.grams') }}</span>
    <template v-if="weight && price">
      <span>/</span>
      <span>{{ currencyCode }}</span>
      <span>{{ formattedPrice }}</span>
    </template>
    <template v-else-if="price">
      <span>{{ currencyCode }}</span>
      <span>{{ formattedPrice }}</span>
    </template>
    <slot name="afterPrice" />
  </div>
</template>

<script setup lang="ts">
import type { Schemas } from '#shopware'

const props = withDefaults(
  defineProps<{
    item: Schemas['LineItem'] | Schemas['OrderLineItem']
    price?: boolean
  }>(),
  {
    price: true,
  }
)

const { currencyCode } = usePrice()

const formattedPrice = computed(() => {
  if (props.item.apiAlias && props.item.apiAlias === 'order_line_item') {
    return props.item.totalPrice.toFixed(2)
  }
  if (props.item.price) {
    return props.item.price.totalPrice.toFixed(2)
  }
  return '-'
})

const weight = computed(() => props.item.payload?.weight ?? null)
</script>
